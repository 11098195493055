export function searchElementForSelector(el, s) {
  /* Loop up the DOM tree through parent elements to try to find an element that matches the given selector */
  while (el && (el.tagName && !el.matches(s))) {
    el = el.parentNode;
  }

  if (el && el.tagName && el.matches(s)) {
    return el;
  }

  return null;
}
