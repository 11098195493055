import { searchElementForSelector } from './searchElementForSelector.js';
import { delayNavigation } from './delayNavigation.js';
import { matchesPolyfill } from './matchesPolyfill.js';

const AdobeUrls = {
  staging: '//assets.adobedtm.com/60e0841c6ded/d5a97f0ea4af/launch-5a5dc9e26019-staging.min.js',
  production: '//assets.adobedtm.com/60e0841c6ded/d5a97f0ea4af/launch-3bb7433af2ae.min.js',
};

const defaultTrackingSelector = "[data-adobe-track]";

export class Adobe {
  constructor(isStaging, clickSelector = defaultTrackingSelector, win = window, doc = document) {
    this.doc = doc;
    this.win = win;
    this.url = isStaging ? AdobeUrls.staging : AdobeUrls.production;
    this.title = doc.title;
    this.pageUrl = win.location.toString();
    this.searchTerm = null;
    this.resultCount = null;
    this.loggingEnabled = false;
    this.targetSelector = clickSelector;

    matchesPolyfill();
  }

  enableLogging() {
    this.loggingEnabled = true;
  }

  disableLogging() {
    this.loggingEnabled = false;
  }

  adobeStyleElementFunction() {
    // this function was provided by the client, adding it here instead of inlining it in order to centralize
    // the adobe logic
    (function (g, b, d, f) {
      (function (a, c, d) {
        if (a) {
          var e = b.createElement("style");
          e.id = c;
          e.innerHTML = d;
          a.appendChild(e)
        }
      })(b.getElementsByTagName("head")[0], "at-body-style", d);
      setTimeout(function () {
        var a = b.getElementsByTagName("head")[0];
        if (a) {
          var c = b.getElementById("at-body-style");
          c && a.removeChild(c)
        }
      }, f)
    })(this.win, this.doc, "body {opacity: 0 !important}", 3E3);
  }

  addScripts() {
    this.adobeStyleElementFunction();

    const scriptEl = this.doc.createElement('script');
    scriptEl.src = this.url;
    scriptEl.async = true;
    scriptEl.onload = () => {
      this.loaded = true;
      this.handlePageView();
      this.watchForClicks();
    };
    this.doc.head.appendChild(scriptEl);
  }

  firePayload(payload) {
    if (!this.loaded) {
      setTimeout(() => {
        this.firePayload(payload);
      }, 1000)
    }

    if (this.loggingEnabled) console.log(payload);
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(payload);
  }

  setGlobalSearchInfo(term,resultCount) {
    this.searchTerm = term;
    this.resultCount = resultCount;
  }

  handlePageView() {
    this.firePayload({
      "event": "yextAnswersPageView",
      "timestamp": this.getTodaysDateWithMilliseconds(),
      "attributes": {
        "yext": {
          "yextModule": "yextGlobalSearch",
          "pageTitle": this.title,
          "pageUrl": this.pageUrl,
        }
      }
    })
  }

  handleSearch(searchTerm, resultCount) {
    this.setGlobalSearchInfo(searchTerm, resultCount);
    this.firePayload({
      "event": resultCount === 0 ? "yextAnswersZeroSearchResults" : "yextAnswersSearchConducted",
      "timestamp": this.getTodaysDateWithMilliseconds(),
      "attributes": {
        "yext": {
          "yextModule": "yextGlobalSearch",
          "searchTerm": searchTerm,
          "yextNumberOfResults": resultCount,
          "pageTitle": this.title,
          "pageUrl": this.pageUrl
        }
      }
    });
  }

  watchForClicks() {
    this.doc.addEventListener('click', (e) => {
      const trackedElement = searchElementForSelector(e.target, this.targetSelector);
      if (!trackedElement) return;
      const trackingDataString = trackedElement.dataset.adobeTrack;
      if (!trackingDataString) return;
      let trackingData = null;
      try {
        trackingData = JSON.parse(trackingDataString);
      } catch (error) {
        console.error('error parsing tracking data', trackingDataString, error);
        return;
      }

      delayNavigation((navigate)=> {
        this.handleClick(trackingData);
        if (navigate) {
          if(this.loggingEnabled) console.log('delaying navigation');

          setTimeout(() => {
            navigate();
          }, 200)
        }
      }, e);
    });
  }

  handleClick(trackingData) {
    this.firePayload({
      "event": "yextAnswersClickedResult",
      "timestamp": this.getTodaysDateWithMilliseconds(),
      "attributes": {
        "yext": {
          "yextModule": "yextGlobalSearch",
          "searchTerm": this.searchTerm,
          "searchResultTitle": trackingData.title,
          "searchResultURL": trackingData.url,
          "searchResultIndex": trackingData.index,
          "pageTitle": this.title,
          "pageUrl": this.pageUrl
        }
      }
    });
  }

  getTodaysDateWithMilliseconds() {
    const today = new Date(Date.now());
  
    const formattedDateString = today.toDateString();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const milliseconds = today.getMilliseconds();
    const timeString = today.toTimeString();
    const timeStringEnding = timeString.substring(timeString.indexOf(" ") + 1);
  
   return formattedDateString + " " + hours + ":" + minutes + ":" + seconds + ":" + milliseconds + " " + timeStringEnding;
  }
}
